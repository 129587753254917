import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { BiUser } from "react-icons/bi";

import logo from "../../contents/images/napalogo1.png";
import "./navbar.css";
import { UserProfile } from "../user-profile/userProfile";
import { getRequest } from "../../actions/Handler";
import { useMsal } from "@azure/msal-react";

export default function Navigation() {
  const Authentication = sessionStorage.getItem("token");
  const [show, setShow] = useState(false);
  let userDetail = JSON.parse(
    sessionStorage.getItem("userDetail") || "{}"
  ) as any;

  const provider = "Provider";
  const siteReviewer = "Site Reviewer";
  const powerBIURL = process.env.REACT_APP_ANALYTICSURL;
  const redirectURL=`${window.location.origin}/login`;

  const [profilePicture, setProfilePicture] = useState("");
  useEffect(() => {
    if (Authentication) {
      getProfilePicture();
    }
    if (userDetail) {
      setProfilePicture(
        userDetail.profilePicture ? userDetail.profilePicture : ""
      );
    }
  }, []);
  
  const homeAccountId= JSON.parse(sessionStorage.getItem("account") || '{}')
  const { instance } = useMsal();
  let det=instance.getAccountByHomeId(homeAccountId.homeAccountId);
  delete det?.idTokenClaims
  const logoutRequest = {
    account: det,
    postLogoutRedirectUri: redirectURL,
  };
  const handleLogout = () => {
    sessionStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect(logoutRequest).catch(e => {
      console.error(e);
  });
  };

  const toggleShow = () => setShow((show) => !show);

  // when menu button is clicked filter data inputs will be cleared
  const clearFilterDatas = () => {
    sessionStorage.setItem("eventReportId", JSON.stringify(null))
    sessionStorage.setItem("siteName", JSON.stringify(null))
    sessionStorage.setItem("subCategory", JSON.stringify(null))
    sessionStorage.setItem("serviceLine", JSON.stringify(null))
    sessionStorage.setItem("gender", JSON.stringify(null))
    sessionStorage.setItem("age", JSON.stringify(null))
    sessionStorage.setItem("ageUnit", JSON.stringify(null))
    sessionStorage.setItem("status", JSON.stringify(null))
    sessionStorage.setItem("reviewStatus", JSON.stringify(null))
    sessionStorage.setItem("region", JSON.stringify(null))
    sessionStorage.removeItem("dos")
    sessionStorage.removeItem("dosFromDate")
    sessionStorage.removeItem("dosToDate")
    sessionStorage.setItem("isAscending",JSON.stringify(false))
    sessionStorage.setItem("sortedColumn","eventReportId")
    sessionStorage.setItem("sortOrder",JSON.stringify(-1));
    sessionStorage.setItem("pageLimit",JSON.stringify(25));
    sessionStorage.setItem("pageNo",JSON.stringify(0));
    sessionStorage.setItem("pageFirst",JSON.stringify(0));
  }

  const getProfilePicture = () => {
    getRequest({
      url: "api/Identity/GetUserProfilePicture",
    }).then((res) => {
      if (res != undefined) {
        const userDetail = JSON.parse(
          sessionStorage.getItem("userDetail") || "{}"
        ) as any;
        userDetail.profilePicture = res;
        sessionStorage.setItem("userDetail", JSON.stringify(userDetail));
        if (userDetail) {
          setProfilePicture(
            userDetail.profilePicture ? userDetail.profilePicture : ""
          );
        }
      }
    });
  };

  return Authentication ? (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Home - NQIPS</title>
        </Helmet>
      </HelmetProvider>
      <div className="header">
        <Container fluid={true} className="p-0 d-flex flex-row">
          <Col>
            <Container
              fluid={true}
              className="p-0 d-flex flex-row header-margin"
            >
              <Col className="mb-2">
                <img
                  id="siteLogo"
                  alt="site logo"
                  src={logo}
                  className="logoClass pt-1 ps-1"
                />
              </Col>
              <Col className="d-flex flex-row header-end">
                <div className="user align-items-center">
                  <span className="me-1 d-none d-sm-block" onClick={toggleShow}>
                    {userDetail.fullName ? userDetail.fullName : ""}
                  </span>
                  {(() => {
                    if (profilePicture === "" || !profilePicture) {
                      return (
                        <BiUser className="user_icon" onClick={toggleShow} />
                      );
                    } else {
                      return (
                        <img
                          src={`data:image/jpeg;base64,${profilePicture}`}
                          className="imageClass1"
                          onClick={toggleShow}
                          alt="toggle"
                        />
                      );
                    }
                  })()}
                </div>
              </Col>
            </Container>
          </Col>
        </Container>
      </div>
      <Navbar
        collapseOnSelect
        fixed="top"
        expand="sm"
        className="navbar"
        style={{ position: "inherit" }}
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav>
            {(() => {
              if (userDetail.role === provider || (userDetail.role === siteReviewer && !userDetail.isPSOCertified)) {
                return (
                  <Nav.Link className="navlist" eventKey="2" href="/my-event-reports" onClick={(e: any) => {
                    clearFilterDatas();
                  }}>
                    My Event Reports
                  </Nav.Link>
                );
              } else if (userDetail.role === siteReviewer && userDetail.isPSOCertified) {
                return (
                  <>
                    <Nav.Link
                      className="navlist"
                      eventKey="1"
                      href="/all-reports"
                      onClick={(e:any) =>{
                        clearFilterDatas();
                      }}
                    >
                      All Reports
                    </Nav.Link>
                    <Nav.Link
                      className="navlist"
                      eventKey="2"
                      href="/my-event-reports"
                      onClick={(e:any) =>{
                        clearFilterDatas();
                      }}
                    >
                      My Event Reports
                    </Nav.Link>
                  </>
                );
              } else {
                return (
                  <>
                    <Nav.Link
                      className="navlist"
                      eventKey="1"
                      href="/all-reports"
                      onClick={(e: any) => {
                        clearFilterDatas();
                      }}
                    >
                      All Reports
                    </Nav.Link>
                    <Nav.Link
                      className="navlist"
                      eventKey="2"
                      href="/my-event-reports"
                      onClick={(e: any) => {
                        clearFilterDatas();
                      }}
                    >
                      My Event Reports
                    </Nav.Link>
                    <Nav.Link
                      className="navlist"
                      eventKey="4"
                      href={powerBIURL} target="_blank"
                    >
                      Analytics
                    </Nav.Link>
                    <Nav.Link className="navlist" eventKey="4" href="/manage-site-reviewer" onClick={(e:any) =>{
                      clearFilterDatas();
                    }}>
                      Manage Site Reviewer PSO Training
                    </Nav.Link>
                  </>
                );
              }
            })()}
            {/* <Nav.Link className="navlist" eventKey="1" href="/all-sites">
              All Reports
            </Nav.Link> */}
            {/* <Nav.Link className="navlist" eventKey="2" href="/my-event-reports">
              My Event Reports
            </Nav.Link> */}
            {/* <Nav.Link className="navlist" eventKey="3" href="/all-sites">
              My Sites
            </Nav.Link> */}
            {/* <Nav.Link className="navlist" eventKey="4" href="/all-sites">
              Analytics
            </Nav.Link>
            <Nav.Link className="navlist" eventKey="4" href="/manage-site-reviewer">
              Manage Site Reviewer
            </Nav.Link>
            </Nav.Link> */}

            <Nav.Link
              className="navlist"
              eventKey="5"
              onClick={() => handleLogout()}
            >
              Sign Out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <UserProfile
        show={show}
        toggleShow={toggleShow}
        handleLogout={handleLogout}
        profilePic={userDetail?.profilePicture}
      ></UserProfile>
    </>
  ) : null;
}
