const ssoData = {
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID.toString(),
  AUTHORITY: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTHORITY.toString()}`,
  SCOPE: process.env.REACT_APP_SCOPE.toString(),
};
export const msalConfig = {
  auth: {
    clientId: ssoData.CLIENT_ID,
    authority: ssoData.AUTHORITY,
    redirectUri: `${window.location.origin}/login`,
    postLogoutRedirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [ssoData.SCOPE],
  prompt: "login",
};
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
