import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";

import Navigation from "./components/navbar/navbar";
import Login from "./screens/login/login";

import ProtectedRoute from "./actions/protectedRoutes";
import LoginProtectedRoute from "./actions/loginProtect";
import Idletimercontainer from "./actions/idleTimerContainer";
import "./App.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import "./authConfig";
const AllSites = lazy(() => import("./screens/all-sites/all-sites"));
const EventReport = lazy(() => import("./screens/event-report/event-report"));

const MyEventReports = lazy(
  () => import("./screens/my-event-reports/my-event-reports")
);

const ManageSiteReview = lazy(
  () => import("./screens/manage-site-reviewer/manage-site-reviewer")
);
 
function App() {
  if (sessionStorage.getItem("userDetail") === null 
  && (window.location.pathname.includes("event/report/edit") 
  || window.location.pathname.includes("event/report/view"))) {
    sessionStorage.setItem("previousURL", window.location.pathname);
  }
  let userDetail = JSON.parse(
    sessionStorage.getItem("userDetail") || "{}"
  ) as any;
  return (
    <>
      <Idletimercontainer></Idletimercontainer>
      <ToastContainer />
      <Router>
        <Routes>
          <Route element={<LoginProtectedRoute />}>
            <Route path="/login" element={<Login />}></Route>
          </Route>
        </Routes>
        <main>
          <Navigation />
          <div>
            <Suspense fallback={<div id="loader"></div>}>
              <Routes>
                <Route
                  path="/"
                  element={userDetail.role === "Provider" ? <Navigate replace to="/my-event-reports" /> :
                    <Navigate replace to="/all-reports" />}
                />
                <Route element={<ProtectedRoute />}>
                  <Route path="/all-reports" element={<AllSites />} />
                  <Route
                    path="/event/report/:mode/:eventId"
                    element={<EventReport />}
                  />

                  <Route
                    path="/my-event-reports"
                    element={<MyEventReports />}
                  />

                  <Route
                    path="/manage-site-reviewer"
                    element={<ManageSiteReview />}
                  />
                </Route>
              </Routes>
            </Suspense>
          </div>
        </main>
      </Router>
    </>
  );
}

export default App;
