import axios from "axios";
import { pseudoRandomBytes } from "crypto";
import { json } from "stream/consumers";
import { toast } from "react-toastify";

const DomainUrl = process.env.REACT_APP_API_URL;

const errormess = () => {
  toast("You are not authorized to access this page", {
    type: "error",
    autoClose: 1000,
    pauseOnHover: true,
    delay: 0,
  });
};

let toasterDisplay = true;

const AuthHeaders = () => {
  const axiosConfig: any = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };
  return axiosConfig;
};

interface PostRequestProps {
  url: string;
  data: any;
}

const postRequest = async (props: PostRequestProps) => {
  return axios
    .post(DomainUrl + props.url, props.data, AuthHeaders())
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if(toasterDisplay){
        if (error.response.status === 401) {
          setTimeout(() => {
            sessionStorage.clear();
            sessionStorage.setItem("type", "expired");
            window.location.href = `${window.location.origin}/login`;
          }, 1500);
          errormess();
          toasterDisplay = !toasterDisplay;

        } else {
          throw error.response.data;
        }
      }
      // if (error.response.status === 401) {
      //   setTimeout(() => {
      //     sessionStorage.clear();
      //     sessionStorage.setItem("type", "expired");
      //     window.location.href = `${window.location.origin}/login`;
      //   }, 1500);
      //   errormess();
      // } else {
      //   throw error.response.data;
      // }
    });
};

interface GetRequestProps {
  url: string;
}

const getRequest = async (props: GetRequestProps) => {
  return axios
    .get(DomainUrl + props.url, AuthHeaders())
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      console.log(error);
      if (toasterDisplay) {
        if (error.response.status === 401) {
          setTimeout(() => {
            sessionStorage.clear();
            sessionStorage.setItem("type", "expired");
            window.location.href = `${window.location.origin}/login`;
          }, 1500);
          errormess();
          toasterDisplay = !toasterDisplay;
        } else {
          throw error.response.data;
        }
      }
      // if (error.response.status === 401) {
      //   setTimeout(() => {
      //     sessionStorage.clear();
      //     sessionStorage.setItem("type", "expired");
      //     window.location.href = `${window.location.origin}/login`;
      //   }, 1500);
      //   errormess();
      // } else {
      //   throw error.response.data;
      // }
    });
};

interface DeleteRequestProps {
  url: string;
}

const deleteRequest = async (props: DeleteRequestProps) => {
  return axios
    .delete(DomainUrl + props.url, AuthHeaders())
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if (error.response.status === 401) {
        sessionStorage.clear();
        sessionStorage.setItem("type", "expired");
        window.location.href = `${window.location.origin}/login`;
      } else {
        throw error.response.data;
      }
    });
};

export { postRequest, getRequest, deleteRequest };
