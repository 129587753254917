import { useState, useEffect, useRef, Component } from "react";
import { useMsal } from "@azure/msal-react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import logo from "../../contents/images/napalogo1.png";
import { loginRequest } from "../../authConfig";
import { getRequest } from "../../actions/Handler";
import "./login.css";
import { toast } from "react-toastify";
import { error } from "console";
import { json } from "stream/consumers";
export default function Login() {

  const [state] = useState({
    instance: useMsal().instance,
    accounts: useMsal().accounts,
  });

  const provider = "Provider";
  const siteReviewer = "Site Reviewer";
  const [mounted, setMounted] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = `${window.location.origin}/login`;
  };
  let previousUrl = sessionStorage.getItem("previousURL") as string | null;

  if (!mounted) {
    state.instance.handleRedirectPromise().then((result) => {
      if (result) {
        setLoader(true);
        sessionStorage.setItem("token", result.accessToken);
        sessionStorage.setItem("account", JSON.stringify(result.account));
        sessionStorage.setItem("eventReportId",JSON.stringify(null));
        sessionStorage.setItem("siteName",JSON.stringify(null));
        sessionStorage.setItem("subCategory",JSON.stringify(null));
        sessionStorage.setItem("serviceLine",JSON.stringify(null));
        sessionStorage.setItem("gender",JSON.stringify(null));
        sessionStorage.setItem("age",JSON.stringify(null));
        sessionStorage.setItem("ageUnit",JSON.stringify(null));
        sessionStorage.setItem("status",JSON.stringify(null));
        sessionStorage.setItem("reviewStatus",JSON.stringify(null));
        sessionStorage.setItem("region",JSON.stringify(null));
        sessionStorage.setItem("isAscending",JSON.stringify(false));
        sessionStorage.setItem("sortedColumn","eventReportId");
        sessionStorage.setItem("sortOrder",JSON.stringify(-1));
        sessionStorage.setItem("pageLimit",JSON.stringify(25));
        sessionStorage.setItem("pageNo",JSON.stringify(0));
        sessionStorage.setItem("pageFirst",JSON.stringify(0));
        // sessionStorage.setItem("dos",JSON.stringify(null));
        getRequest({ url: "api/Identity/GetUserDetails" })
          .then((res: any) => {
            sessionStorage.setItem("userDetail", JSON.stringify(res));
            if (res.role === provider || (res.role === siteReviewer && !res.isPSOCertified)) {
              window.location.href = previousUrl !== null ? `${window.location.origin}${previousUrl}` : `${window.location.origin}/my-event-reports`;
            } else {
              window.location.href = previousUrl !== null ? `${window.location.origin}${previousUrl}` : `${window.location.origin}/all-reports`;
            }
            // window.location.href = `${window.location.origin}/all-sites`;
          })
          .catch((error) => {
            errormess();
            setTimeout(() => {
              setLoader(false);
              handleLogout();
            }, 2000);
          });
      } else {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("account");
        setLoader(false);
      }
    });
  }

  if (sessionStorage.getItem("type") === "expired") {
    // setTimeout(() => {
    // }, 1000);
    sessionStorage.clear();
    state.instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  // const toast = useRef<Toast>(null);
  const errormess = () => {
    toast("You are not authorized to access the application", {
      type: "error",
      autoClose: 2000,
      pauseOnHover: true,
      delay: 0,
    });
    // toast.current?.show({
    //   severity: "error",
    //   summary: "Error!",
    //   detail: "You are not authorized to access the application",
    // });
  };
  const handleLogin = (instance: any) => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  };

  return loader || sessionStorage.getItem("type") === "expired" ? (
    <div id="loader">
      {/* <Toast className="toast-demo" ref={toast}></Toast> */}
    </div>
  ) : (
    <>
      <div className="bodyClass"></div>
      <div className="topheader-container">
        <div className="topheader-sliced"></div>
        <div className="topheader"></div>
      </div>
      <div className="main-container">
        <Container className="container-1">
          <div>
            <Typography
              component="div"
              style={{
                backgroundColor: "White",
                opacity: "80%",
                display: "flex",
                flexFlow: "column",
                height: "100%",
              }}
            >
              <div className="center">
                <div>
                  <img className="Napa-Image" src={logo} alt="Napa Logo" />
                </div>
                <div>
                  <p className="Napa-text-00">
                    Please sign in with your @napaanesthesia email address and
                    password.
                  </p>
                </div>
                <button
                  className="Login-button"
                  onClick={() => handleLogin(state.instance)}
                >
                  Login
                </button>
                <div>
                  <p className="Napa-text-passwordHelp">
                    Forgot your password? Contact NAPA IT Support at (516)
                    945-3178 or support@napaanesthesia.com
                  </p>
                </div>
                <div>
                  <p className="Napa-text-02">
                    The information entered into this system is part of a
                    Patient Safety Evaluation System (PSES) and is confidential.
                    All information submitted will be analyzed by a Patient
                    Safety Organization (PSO) and is protected under the Patient
                    Safety and Quality Improvement Act of 2005 (as may be
                    amended from time to time). Disclosure of any Patient Safety
                    Work Product (PSWP) outside of the PSES is not permitted
                    without express consent from NAPA Anesthesia Patient Safety
                    Institute, LLC.
                  </p>
                  <p className="Napa-text-02">
                    By logging into this system, you acknowledge that: a) all
                    information reported into the NQIPS is confidential PSWP,
                    which you intend to report to the PSO and for no other
                    reason, and b) all PSWP and information submitted and
                    analyzed in the PSES is confidential.
                  </p>
                  <p className="Napa-text-02">
                    © North American Partners in Anesthesia. All Rights
                    Reserved.
                  </p>
                  <br></br>
                </div>
              </div>
            </Typography>
          </div>
        </Container>
        <div className="bottomfooter-container">
          <div className="bottomfooter"></div>
          <div className="bottomfooter-sliced"></div>
        </div>
      </div>
    </>
  );
}

