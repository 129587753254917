import React from 'react'
import IdleTimer from 'react-idle-timer'
import { useMsal } from "@azure/msal-react";

const { REACT_APP_IDLE_TIMEOUT } = process.env;

function Idletimercontainer() {
    const auth = sessionStorage.getItem("token");
    const appIdleTimeout = REACT_APP_IDLE_TIMEOUT ? REACT_APP_IDLE_TIMEOUT : "900000";
    const idleTimeout = parseInt(appIdleTimeout);
    const homeAccountId= JSON.parse(sessionStorage.getItem("account") || '{}')
    const redirectURL=`${window.location.origin}/login`;
    const { instance } = useMsal();
    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId.homeAccountId),
      postLogoutRedirectUri: redirectURL,
    };
    const handleOnIdle = () => {
        if (auth) {
            sessionStorage.clear();
            sessionStorage.clear();
            instance.logoutRedirect(logoutRequest).catch(e => {
                console.error(e);
            });
            //window.location.href = `${window.location.origin}/login`;
        }
    }

    return (
        <>
            <IdleTimer
                timeout={idleTimeout}
                onIdle={handleOnIdle}
                debounce={250}
            />
        </>
    )
}

export default Idletimercontainer