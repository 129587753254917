import { useState, useEffect } from "react";
import { Modal, Container, Col } from "react-bootstrap";
import { BiUser } from "react-icons/bi";
// import { Tooltip } from "primereact/tooltip";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/styles";

import "./userProfile.css";

export function UserProfile(props: any) {
  const { show, toggleShow, handleLogout, profilePic } = props;
  const userDetail = JSON.parse(
    sessionStorage.getItem("userDetail") || "{}"
  ) as any;

  const [profilePicture, setProfilePicture] = useState("");
  const isRegion = userDetail.regions != null;
  const hideSites =
    userDetail?.role?.includes("Central") ||
    userDetail?.role?.includes("Provider") ||
    isRegion;

  //tooltip style properties
  const HtmlTooltip = withStyles((theme) => ({
    arrow: {
      fontSize: "8px",
      color: "#495057"
    },
    tooltip: {
      backgroundColor: "#495057",
      color: "white",
      fontSize: "12px",
      marginTop: "4px",
      paddingTop: "10px",
      paddingBottom: "10px",

    }
  }))(Tooltip);

  //tooltip style properties for region/sites
  const HtmlTooltip1 = withStyles((theme) => ({
    arrow: {
      fontSize: "8px",
      color: "#495057",
      left: "50% !important",
      marginLeft: "-5px !important"
    },
    tooltip: {
      backgroundColor: "#495057",
      color: "white",
      fontSize: "12px",
      marginTop: "4px",
      paddingTop: "10px",
      paddingBottom: "10px",

    }
  }))(Tooltip);

  useEffect(() => {
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={toggleShow}
        className="user-profile-popup"
        dialogClassName="custom-dialog"
        size="sm"
        backdropClassName="user-profile-backdrop"
      >
        <Modal.Body>
          <Container fluid className="p-0 d-flex flex-wrap popup-user">
            <Col
              md="12"
              sm="12"
              xs="12"
              className="popup-user p-0 mt-2 text-center"
            >
              {(() => {
                if (profilePic === "" || !profilePic) {
                  return <BiUser className="popup-user-icon" />;
                } else {
                  return (
                    <img
                      src={`data:image/jpeg;base64,${profilePic}`}
                      className="imageClass"
                      alt="profile"
                    />
                  );
                }
              })()}
            </Col>
            <Col md="12" sm="12" xs="12" >
              <Col md="12" sm="12" xs="12" className="p-0">
                <span className="name">
                  {userDetail.fullName ? userDetail.fullName : ""}
                </span>
              </Col>
              <Col md="12" sm="12" xs="12" className="p-0 overflow-ellipsis">
                <HtmlTooltip title={
                  <div style={{ width: "150px" }}>
                    {userDetail.email}
                  </div>
                }
                  arrow>
                  <span className="email">{userDetail.email}</span>
                </HtmlTooltip>
              </Col>
              <Container fluid className="p-0 d-flex flex-wrap">
                <Col md="4" sm="5" xs="5" className="p-0">
                  <span>Role:</span>
                </Col>
                <Col md="8" sm="7" xs="7" className="p-0 overflow-ellipsis">
                  <HtmlTooltip title={userDetail.role} arrow>
                    <span className="rolename">{userDetail.role}</span>
                  </HtmlTooltip>
                </Col>
              </Container>
              {!hideSites && (
                <>
                  <Container fluid className="p-0 d-flex flex-wrap">
                    <Col md="4" sm="5" xs="5" className="p-0">
                      <span>Site(s):</span>
                    </Col>
                    <Col md="8" sm="7" xs="7" className="p-0 overflow-ellipsis">
                      <HtmlTooltip1 title={
                        <div style={{ maxHeight: "150px", overflowY: "auto", maxWidth: "17.5rem" }} className="scrollBarTooltip">
                          {userDetail.sites}
                        </div>
                      }
                        interactive={true} arrow>
                        <span className="sitename">{userDetail.sites}</span>
                      </HtmlTooltip1>
                    </Col>
                  </Container>
                </>
              )}
              {isRegion && (
                <>
                  <Container fluid className="p-0 d-flex flex-wrap">
                    <Col md="4" sm="5" xs="5" className="p-0">
                      <span>Region(s): </span>
                    </Col>
                    <Col md="8" sm="7" xs="7" className="p-0 overflow-ellipsis">
                      <HtmlTooltip1 title={
                        <div style={{ maxHeight: "150px", overflowY: "auto", maxWidth: "17.5rem" }} className="scrollBarTooltip">
                          {userDetail.regions}
                        </div>
                      }
                        interactive={true} arrow>
                        <span className="regionname">
                          {userDetail.regions}
                        </span>
                      </HtmlTooltip1>
                    </Col>
                  </Container>
                </>
              )}
            </Col>
          </Container>
          <Col md="12" sm="12" xs="12" className="text-right pb-0">
            <button className="btn" onClick={handleLogout}>
              Sign out
            </button>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}
